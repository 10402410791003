@import '../styles/variables';

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  border: 2px solid $white;
  border-left: 2px solid $gray30;
  animation: spinning 1s infinite linear;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}

// To be used on white backgrounds
.inverted {
  border: 2px solid $gray30;
  border-left: 2px solid $white;
}
