@import '../styles/variables';

.trigger {
  display: inline-block;
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 999; // TODO: Handle elevations globally

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.container {
  width: 460px;
  max-width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 0.5em;
}
