@import '../~@clerk-ui/components/styles/variables';

.activation {
  display: block;
  color: $black;
  margin-top: 1em;
  margin-left: 1em;
  margin-bottom: 1em;

  svg {
    margin-right: 0.5em;
    display: inline;
    vertical-align: text-bottom;
  }
}
