@import '../styles/variables';
@import '../styles/mixins';

.container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.iconContainer {
  display: inline-flex;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  width: 1.25rem;
  height: 1.25rem;
}

.left {
  > {
    input {
      padding-left: 2.75em;
    }

    .iconContainer {
      left: 1em;
    }
  }
}

.right {
  > {
    input {
      padding-right: 2.75em;
    }

    .iconContainer {
      right: 1em;
    }
  }
}
