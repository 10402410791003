@import '../styles/variables';

.link {
  color: $primary;
  text-decoration: none;

  font-size: 1rem;
  font-weight: $semibold-weight;
  line-height: 1.25rem;

  &:hover {
    text-decoration: underline;
  }
}
