@mixin control {
  @include transitions();

  padding: calc(1em - 2px);

  line-height: 1.25rem;

  background: none;
  border-radius: $themed-border-radius;
  border: 2px solid $gray10;
  color: $themed-font-color;
  &::placeholder {
    color: $themed-font-color-l1;
  }

  @content;
}

@mixin outline($color: $themed-primary) {
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
  outline-offset: 0 !important;

  box-shadow: 0 0 0 2px $color !important;

  // For our custom dropdowns
  &[aria-haspopup] {
    border-color: $color !important;
    box-shadow: none !important;
  }

  $inputTypes: text email tel password;
  @each $i in $inputTypes {
    &[type*='#{$i}'] {
      border-color: $color !important;
      box-shadow: none !important;
    }
  }

  &::-moz-focus-inner {
    border: 0;
  }

  @content;
}

@mixin disabled {
  .disabled {
    pointer-events: none;
    opacity: 0.5;

    @content;
  }
}

@mixin text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @content;
}

@mixin transitions {
  transition: color $transition-duration * 0.5 ease-in-out,
    background-color $transition-duration * 0.5 ease-in-out,
    border-color $transition-duration * 0.5 ease-in-out,
    box-shadow $transition-duration * 0.5 ease-in-out;
}
