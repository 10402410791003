@import '_mixins.scss';
@import '_variables.scss';
@import '_breakpoints.scss';
@import '_themed_mixins';

@import '~bootstrap/scss/bootstrap';

html,
body,
#__next {
  height: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

body,
.cl-component {
  font-size: 16px;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont;
}

.cl-user-button-popup,
.cl-user-button-popup button {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont !important;
}

//:focus:not(:focus-visible) {
//  outline: none;
//}
//
//:focus-visible {
//  @include outline();
//}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
}

.error {
  color: #ff1d17 !important;
}

label {
  margin-bottom: 0; // Reset bootstrap default styles for labels
}

// hide some clerk component elements
.clerk-isolate {
  .cl-auth-form-switch,
  .cl-auth-form-header {
    display: none !important;
  }

  .cl-component {
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .cl-oauth-button-group button {
    width: 100% !important;
  }
}
