@import '~@clerk-ui/components/styles/variables';
@import '~@clerk-ui/components/styles/mixins';

@include disabled;

.container {
  width: 420px;
}

.subtitle {
  margin-bottom: 2em;
  color: $themed-font-color-l1;
}

.footer {
  margin-top: 4em;
  text-align: center;

  a {
    font-weight: $semibold-weight;
  }
}
