@use "sass:list";
@use "sass:map";

$breakpoints: (
  xxsmall: 0px 320px,
  xsmall: 321px 576px,
  small: 577px 768px,
  medium: 769px 992px,
  large: 993px 1200px,
  xlarge: 1201px 10000px,
);

/**
  Examples:
    media(small): single range
    media(small medium large): multiple ranges
    media(upto medium): up to small
    media(from small): from medium and up
 */
@mixin media($args) {
  @if (
    (list.nth($args, 1) == 'upto' or list.nth($args, 1) == 'from') and not
      map.has-key($breakpoints, list.nth($args, 2))
  ) {
    @error "Invalid syntax.";
  }

  @if (list.nth($args, 1) == 'upto') {
    $max: list.nth(map.get($breakpoints, list.nth($args, 2)), 2);
    @media (max-width: $max) {
      @content;
    }
  } @else if (list.nth($args, 1) == 'from') {
    $min: list.nth(map.get($breakpoints, list.nth($args, 2)), 1);
    @media (min-width: $min) {
      @content;
    }
  } @else {
    // handle multiple breakpoints
    @each $bp in $args {
      @if not map.has-key($breakpoints, $bp) {
        @error "Invalid breakpoint: `#{$bp}`. Available breakpoints are: #{map.keys($breakpoints)}";
      } @else {
        $min: list.nth(map.get($breakpoints, $bp), 1);
        $max: list.nth(map.get($breakpoints, $bp), 2);
        @media (min-width: $min) and (max-width: $max) {
          @content;
        }
      }
    }
  }
}
