.container {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.textCentered {
  text-align: center;
}
