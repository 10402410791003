@import '../styles/variables';
@import '../styles/mixins';

@include disabled;

.button {
  @include control;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;

  font-family: $themed-button-font-family !important;
  font-weight: $themed-button-font-weight !important;
  font-size: 1rem;

  margin: 0;
  padding: 1em 2em;

  border: 0;
  outline: 0;

  &:hover {
    filter: brightness(110%);
  }

  &:active {
    filter: brightness(90%);
  }
}

.primary {
  color: $themed-button-font-color;
  background: $themed-primary;
  border-color: $themed-primary;
}

.warning {
  color: $warning;
  background: $warning;
  border-color: $warning;
}

.error {
  background: $error;
  color: $error;
  border-color: $error;
}

.success {
  background: $success;
  color: $success;
  border-color: $success;
}

// fill:
.solid {
  color: $themed-button-font-color;
}

.text {
  background: none;
  border: 0;
  color: $themed-primary;

  &:hover {
    color: $themed-button-font-color;

    &.primary {
      background-color: $themed-primary;
    }

    &.warning {
      background-color: $warning;
    }

    &.error {
      background-color: $error;
    }

    &.success {
      background-color: $success;
    }
  }
}

.outline {
  background: none;
  border: 2px solid;
}

.hoverable:hover {
  &.primary {
    color: $themed-primary;
    background-color: rgba($themed-primary-rgb, 0.12);
  }

  &.warning {
    color: $warning;
    background-color: rgba($warning, 0.12);
  }

  &.error {
    color: $error;
    background-color: rgba($error, 0.12);
  }

  &.success {
    color: $success;
    background-color: rgba($success, 0.12);
  }
}

.icon {
  background: none;
  padding: 0;
  color: $themed-primary;
}

.link {
  color: $themed-primary;
  background: none;
  border: none;
}

// size
.small {
  font-size: 0.875rem;
  padding: 0.75rem 1.5rem;
}
