@import '~@clerk-ui/components/styles/variables';

.blankSlate {
  color: $gray30;
  font-size: 1em;
  font-weight: $semibold-weight;

  text-align: center;

  min-height: 300px;
}
