@import '~@clerk-ui/components/styles/variables';

.message {
  color: $themed-font-color-l1;
  margin: 0.5em 0 2em;
}

.button {
  background-color: $black;
}
