@import '../styles/variables';
@import '../styles/mixins';
@import '../styles/main';

@include disabled;

.container {
  position: relative;
  max-width: 600px;
}

.trigger {
  * {
    cursor: pointer;
  }

  align-items: center;
  @include control {
    width: 100%;

    display: flex;
    justify-content: space-between;
    text-align: left;

    cursor: pointer;
  }

  &.active {
    border-color: $themed-primary;
  }
}

.label {
  margin: 0;
  padding: 0 0.5em;
  flex: 1 1 auto;
}

.labelTertiary {
  padding: 0;
}

.placeholder {
  color: $themed-font-color-l1;
}

.label {
  @include text-ellipsis;
}

.selected {
  background: $gray10;
}

.active {
  opacity: 1;
}

.searchInput {
  font-size: 0.8rem;
  width: 85%;
  background-color: $white;
  width: 100%;

  padding: 0;
  border: none;
  border-radius: unset;
  outline: none;

  &:focus {
    border-color: $themed-primary;
  }
}

.searchDisplay {
  pointer-events: none;
  position: absolute;
  padding: 0 2rem;
}

.nativeSelect {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @include media(from small) {
    display: none;
  }
}

.tertiary {
  width: fit-content;
  border: none;
  padding: 0;
}
