@import '~@clerk-ui/components/styles/variables';

.logos {
  margin: 0 0 4em;

  img {
    width: 4em;
  }
}

.plus {
  vertical-align: middle;

  font-size: 2rem;
  color: $themed-font-color-l1;
  font-weight: $semibold-weight;

  padding: 0 1em;
}
