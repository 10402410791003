@import '../styles/variables';
@import '../styles/mixins';

.notifications {
  position: fixed;
  top: 6em;
  right: 2em;
  width: 460px;
  max-height: 33.3vh;

  overflow-y: auto;

  border-radius: 0.5em;
  background-color: $white;
  padding-left: 2em;

  z-index: 1000; // TODO: Handle elevations globally

  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));

  animation: slideIn 100ms 1 ease;
}

.content {
  position: relative;
  padding: 1.5em 3.25em 1.5em 1em;
  line-height: 1.25rem;

  p {
    margin: 0;
  }
}

.closeButton {
  position: absolute;
  top: 1.5em;
  right: 1em;
  color: $themed-font-color-l1;
  cursor: pointer;
}

.title {
  font-weight: $semibold-weight;
  font-size: 1rem;
  line-height: 1.25rem;
}

.icon {
  position: absolute;
  top: 0px;
  left: 0px;

  height: 100%;
  width: 2em;

  content: 'x';

  padding-top: 1.25em;
  padding-left: 0.5em;

  svg {
    color: $white;
  }

  &.info {
    background: $primary;
  }

  &.warning {
    background: $warning;
  }

  &.success {
    background: $success;
  }

  &.error {
    background: $error;
  }
}

@keyframes slideIn {
  0% {
    transform: translate(5px);
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  to {
    transform: translate(0);
  }
}
