.form {
  max-width: 660px;

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  fieldset + fieldset {
    margin-top: 2em;
  }
}

.group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.reversed {
  flex-direction: row-reverse;
}
