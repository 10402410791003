@import '../styles/main';

.button {
  all: unset;
}

.elementContainer {
  display: flex;
  width: 100%;
  box-sizing: border-box;

  &:last-of-type div {
    border-bottom: 0;
  }

  @include media(upto xsmall) {
    flex-direction: column;
  }

  div {
    white-space: normal;
  }
}

.title {
  display: flex;
  flex: 0 0 30%;
  margin-right: 0.5em;
  padding-top: themed-padding(1.5em);
  line-height: 1.25em;
  font-size: 1em;
  font-weight: $themed-label-font-weight;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding-top: themed-padding(1.5em);
  padding-bottom: themed-padding(1.5em);
  font-size: 1em;
  line-height: 1.25em;
}

.hoverable:hover {
  background-color: $gray05;
}

.line {
  border-bottom: 2px solid $gray10;
}

.start,
.end {
  display: inline-block;
}

.start {
  flex: 2 1 0;
  margin-right: 2em;
  @include text-ellipsis;

  @include media(xsmall) {
    margin-right: 1em;
  }
}

.end {
  flex: 1 1 0;
}

.iconContainer {
  margin-left: auto;
  right: 0;
  margin-right: 2.125em;

  @include media(xsmall) {
    margin-right: 1.5em;
  }
}

.icon {
  color: $themed-primary;
}

.disabled {
  opacity: 0.8;
}

.empty {
  color: $themed-font-color-l1;
}
